<template>
  <div class="index">
        <!-- 顶部：提示语 输入框 -->
        <div class="pageContent_top_content" :style="packUp?'margin-bottom: 1.2rem;':''">
            <div class="pageContent_top" @click.stop="packUp=false" :style="packUp?'height: 1.61rem;':''">
                 <div class="top_text">
                    <div><i class="el-icon-back" @click="$router.back()"></i></div>
                    <div style="width:100%">我要寻舱</div>
                    <!-- <div class="contact_icon"><img src="../../web/img/h5_12.png"></div> -->
                </div>
                <!-- 输入框 -->
                <div class="top_serch" :style="packUp?'height:2.3rem':''" @click.stop="packUp=true">
                    <div class="serch_folding"  @click.stop="packUp=true" :style="packUp?'display:none':''">
                        <div v-if="params.startSeaport">{{params.startSeaport}};{{params.targetSeaport}}</div>
                        <div v-else>深圳; WAIGAOQIAO,SHANGHAI,CN</div>
                        <div class="serch_icon"><img src="../../web/img/h5_2.png"></div>
                    </div>
                   <!-- 展开的输入框 -->
                    <div class="serch_packUp" v-if="packUp">
                        <!-- 开始 -->
                        <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_6.png">
                            </div>
                            <el-select  v-model="params.startSeaport" filterable clearable :filter-method="getFreightPort" @change="getFreightPort" @clear="params.startSeaport = '';params.targetSeaport = ''" popper-class="input_class" size="small" placeholder="深圳">
                                <el-option
                                v-for="item in FreightPortList1"
                                :key="item.id"
                                :label="item.name"
                                :value="item.cname">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 到达 -->
                        <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_7.png">
                            </div>
                                <el-select  v-model="params.targetSeaport" filterable clearable :filter-method="getFreightPort1" @change="getFreightPort1" @clear="params.targetSeaport = '';params.startSeaport = ''" popper-class="input_class" size="small" placeholder="LOS ANGELES,CA">
                                <el-option
                                v-for="item in FreightPortList3"
                                :key="item.id"
                                :label="item.name"
                                :value="item.cname">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 船司 -->
                       <div class="packUp_item">
                            <div class="packUp_icon">
                                <img src="../../web/img/h5_8.png">
                            </div>
                            <el-select  v-model="params.shipCompanyName" filterable clearable :filter-method="getFreightCompany" @change="getFreightCompany" @clear="params.shipCompanyName = ''" popper-class="input_class" size="small" placeholder="船司">
                                <el-option
                                v-for="item in companyList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 柜型&时间 -->
                        <div class="packUp_item_bottom">
                            <!-- 柜型 -->
                             <div class="packUp_item packUp_item2">
                                <div class="packUp_icon packUp_icon2 packUp_icon4">
                                    <img src="../../web/img/h5_9.png">
                                </div>
                                <el-select  v-model="params.cabinetShape" filterable clearable :filter-method="getFreightDefineEnums" @change="getFreightDefineEnums" @clear="params.cabinetShape = ''" popper-class="input_class" size="small" placeholder="柜型">
                                    <el-option
                                    v-for="item in getFreightDefineEnumsList.boxModes"
                                    :key="item.index"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <!-- 时间 -->
                             <div class="packUp_item packUp_item3">
                                <div class="packUp_icon packUp_icon3">
                                    <img src="../../web/img/h5_10.png">
                                </div>
                                    <el-date-picker
                                    v-model="value1"
                                    type="date"
                                    placeholder="选择日期"
                                    size="small"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    :editable="false">
                                    </el-date-picker>
                            </div>
                        </div>
                        <div class="packUp_button" @click.stop="serchFun">查询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pageContent_bottom" @click="packUp=false">
            <!-- 内容：优选航线 更多商品 舱位 下拉加载-->
            <div class="pageContent_shippingSpace">
                <!-- 舱位 -->
                <template v-if="(tableData || []).length>0">
                    <van-list
                        v-model="loading"
                        :finished="noData"
                        finished-text="没有更多了"
                        @load="layoutData"
                        >
                        <div v-for="(item,index) in tableData" :key="index" @click="telShow = true" class="shippingSpace_item">
                            <!-- 左边 -->
                            <div class="item_left">
                                <div class="item_top">
                                    <div>{{item.startPortName1}}</div>
                                    <div class="item_top_right">
                                        <div class="item_text">{{item.voyageDay}}天</div>
                                        <div class="item_icon1"><img src="../../web/img/h5_1.png" alt=""></div>
                                    </div>
                                </div>
                                <div class="item_bottom">
                                    <div>
                                        <div class="item_icon2"><img src="../../web/img/h5_3.png" alt=""></div>
                                        <div>{{item.cabinetShape}}</div>
                                    </div>
                                    <div>
                                        <div class="item_icon3"><img src="../../web/img/h5_4.png" alt=""></div>
                                        <div>{{item.shipCompanyName}}</div>
                                    </div>
                                    <div>
                                        <div class="item_icon4"><img src="../../web/img/h5_5.png" alt=""></div>
                                        <div>{{item.weekcloseFile}}/{{item.weekSail}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 右边 -->
                            <div class="item_right">
                                <p>{{item.targetPortName}}</p>
                                <p>${{item.cabinetAmount}}</p>
                            </div>
                        </div>
                    </van-list>
                </template>
                
                <div  class="loadingCenter" v-show="(tableData || []).length<=0">
                    <div class="nullPage_icon">
                        <img src="../../web/img/h5_13.png" alt="">
                    </div>
                    <div class="nullPage_p1">{{params.startSeaport}}--{{params.targetSeaport}}</div>
                    <div>没有查询到舱位结果</div>
                </div>
            </div>      
        </div> 
        <van-dialog
            v-model="telShow"
            title="服务热线"
            :message="`确认拨打0755-25100671服务热线吗？`"
            show-cancel-button
            @confirm="contact"
            >
        </van-dialog>
  </div>
</template>

<script>
let _ = require('lodash')
export default {
    data() {
        return {
            telShow: false, // 显示电话弹框
            packUp:false,//控制展开的输入框
            value1:'',
            noData: false, // 无数据显示
            loading: false,
            params: {
                cabinetShape: "",
                order: "",
                pageIndex: 1,
                pageSize: 20,
                sailTime: "",
                sailTimeEnd: "",
                sailTimeStart: "", //开始
                shipCompanyName: "",
                status: 1
            },
            pickerOptions: {
                disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7; //禁止选择今天以前的时间
                },
            },
            tableData: [],
            FreightPortList1: [], // 出发地列表数据
            FreightPortList3: [], // 到达地列表数据
            companyList: [], // 船司列表数据
            getFreightDefineEnumsList: {}, // 柜型列表数据
        };
    },
    created(){
        this.getFreightPort();
        this.getFreightPort1();
        this.getFreightCompany();
        this.getFreightDefineEnums()
        let query=this.$route.query;
        if(query.value){
            let params = JSON.parse(query.value)
            this.value1 = params.sailTime
             if(params.value1 != null && params.value1.length != 0){
                this.params.sailTime = params.sailTime
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
            params.pageSize = 15
            this.params =params
        } else {
           this.params= {
            startSeaport: '',
            changeSeaport: '',
            sailTimeStart: '',
            sailTimeEnd: '',
            targetSeaport: '',
            shipCompanyName: '',
            cabinetShape: '',
            pageSize: 15,
            pageIndex: 1,
            status: 1,
            order: '',
          }
        }
        console.log(JSON.parse(JSON.stringify(this.params)))
        this.layoutData();
    },
    methods:{
        // 打电话
        contact() {
            const a = document.createElement("a");
            a.href = "tel:0755-25100671"
            a.click();
            this.show = false;
        },
        // 出发地数据列表
        getFreightPort(val){
            this.params.startSeaport = val
            this.$post('/system/getFreightPort',{name:val}).then(res =>{
                this.FreightPortList1 = res.data.list
                console.log(this.params.startSeaport)
                if(!val){
                    this.FreightPortList1.unshift({
                        cname:'深圳',
                        name:'SHENZHEN',
                        id:999999
                    })
                }
            })
        },
        // 到达地数据列表
         getFreightPort1(val){
            this.params.targetSeaport = val
            this.$post('/system/getFreightPort',{type:'target',name:val}).then(res =>{
                this.FreightPortList3 = res.data.list;
            })
        },
        // 船司数据列表
         getFreightCompany(val){
            this.params.shipCompanyName = val
            this.$post('/system/getFreightCompany',{name:val}).then(res =>{
                this.companyList = res.data.list
            })
        },
        // 柜型数据列表
        getFreightDefineEnums(val){
            this.params.cabinetShape = val
            this.$get('/system/getFreightDefineEnums').then(res =>{
                this.getFreightDefineEnumsList = res.data
            })
        },
        // 加载列表数据
        layoutData(){
            if (this.busy) {
                return false;
            }
            this.$post("/freight/searchFreight4appGatherSimple", this.params).then(
                (res) => {
                res.data.list.forEach((ele) => {
                    let data1 = new Date(ele.sailTime).getDay();
                    let data2 = new Date(ele.closePassTime).getDay();
                    if (data1 == 0) {
                    ele.weekSail = 7;
                    } else {
                    ele.weekSail = data1;
                    }
                    if (data2 == 0) {
                    ele.weekcloseFile = 7;
                    } else {
                    ele.weekcloseFile = data2;
                    }
                    if (ele.surchargeAmountDetail) {
                    if (
                        ele.surchargeAmountDetail != "[]" &&
                        ele.surchargeAmountDetail != "[\u0000]"
                    ) {
                        ele.surchargeAmountDetail = JSON.parse(
                        ele.surchargeAmountDetail
                        );
                        let usdTotal = 0;
                        let cnyTotal = 0;
                        ele.surchargeAmountDetail.forEach((ele1) => {
                        if (ele1.feeType == "CNY") {
                            cnyTotal += ele1.fee;
                        }
                        if (ele1.feeType == "USD") {
                            usdTotal += ele1.fee;
                        }
                        ele.usdTotal = usdTotal;
                        ele.cnyTotal = cnyTotal;
                        });
                    } else {
                        ele.surchargeAmount = 0;
                        delete ele.surchargeAmountDetail;
                    }
                    }
                    if (ele.supplyBizUserName) {
                    ele.mingzi = ele.supplyBizUserName.substr(0, 1) + "*";
                    } else {
                    }

                    if (ele.remark == "备注:") {
                    delete ele.remark;
                    }
                    if (ele.depositAmount == 0) {
                    ele.depositAmount = "--";
                    } else if (!ele.depositAmount) {
                    ele.depositAmount = "--";
                    }
                    ele.startPortName1 = this.getParenthesesStr(ele.startPortName);
                    ele.compositeGrade = 5;
                    ele.closeFileTime = ele.closeFileTime.substr(0, 10);
                    ele.closePassTime = ele.closePassTime.substr(0, 10);
                    ele.sailTime = ele.sailTime.substr(0, 10);
                });

                if (res.data.list.length != 0) {
                    this.tableData = this.tableData.concat(res.data.list);
                
                    this.params.pageIndex += 1;
                    this.loading = false
                    // this.tops()
                    return false
                }
                if(res.data.list.length == 0){
                    this.noData = true
                }
                console.log(this.tableData);
                }
            );
        },
        getParenthesesStr(text) {
            let result = "";
            let regex = /\((.+?)\)/g;
            let options = text.match(regex);
            let option = options[0];
            result = text.replace(/\((.+?)\)/g, "");
            return result;
        },
        //使得返回顶部过渡置增加交互效果
        tops(){
            setTimeout(()=>{
                window.scrollTo(0, this.scrollHeight)
                this.loading = false
            },300)
        },
        serchFun: _.throttle(function () {
            let histroyData = localStorage.getItem('histroyData')
            this.noData=false
             if(this.params.startSeaport && this.params.targetSeaport){
                if (!histroyData){
                    histroyData = []
                } else {
                    histroyData = JSON.parse(histroyData)
                }
                let show = true;
                for(let i =0 ;i<histroyData.length; i++){
                    let item = histroyData[i];
                    if(item.startSeaport == this.params.startSeaport){
                        show = false;
                    }
                }
                if(show){
                    histroyData.unshift({
                        startSeaport:this.params.startSeaport,
                        targetSeaport:this.params.targetSeaport
                    })
                }
                localStorage.setItem('histroyData',JSON.stringify(histroyData))
            }
            // debugger
            // return false
            this.seachInount = 1
            this.params.pageIndex = 1
            //  this.getData()
              if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
             if(this.value1 != null && this.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            }
            //  return false
            //  this.$router.push('/web/serch?value=' + JSON.stringify(this.params))
            let params = this.params
            params.userId =  localStorage.getItem("empId")
            let userInfo = localStorage.getItem('userInfo')
            if(userInfo !=null){
                 params.mobileNum  = JSON.parse(userInfo).user.phone
            }
            if(this.params.startSeaport == undefined){
                this.params.startSeaport = ''
            }
             if(this.params.targetSeaport == undefined){
                this.params.targetSeaport = ''
            }
            this.$post('/index/click?sailTime=' + this.params.sailTime + '&=' + '&startSeaport=' + this.params.startSeaport + '&targetSeaport=' + this.params.targetSeaport + '&companyName='+ this.params.shipCompanyName + '&cabinetShape=' + this.params.cabinetShape + '&mobileNum=' + params.mobileNum + '&userId=' + this.params.userId,this.params).then(res =>{
                this.tableData=[]
                this.layoutData()
            })
        }, 3000),
        // 下拉加载
        scrollBottom() {
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //  console.log(scrollHeight)
            //  console.log(scrollTop,scrollHeight,(Math.floor(scrollTop)),Math.floor(windowHeight), Math.floor(scrollHeight))
            //滚动条到底部的条件
            
            if(this.noData ){
                this.loading = this.false
                return false
            }
            if(this.tableData.length == 0){
                return false
            }
            if(Math.floor(scrollTop)+ Math.floor(windowHeight)  == Math.floor(scrollHeight) && !this.loading){
                this.loading = true
                this.scrollHeight = windowHeight
                this.layoutData()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.index{
    background: #F5F5F5;
    box-sizing: border-box;
}

.pageContent_top{
    width: 100%;
    height: 1.03rem;
    background: linear-gradient(92deg, #73DAFF 0%, #1677FF 68%);
    opacity: 1;
    padding: 0.13rem 0.26rem;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    box-sizing: border-box;
}

.top_text{
    display: flex;
    // justify-content: space-between;
    font-size: 0.16rem;
    color: #fff;
    text-align: center;
    width: 100%;
    flex: 1;
    margin-bottom: 0.15rem;
}

.top_text>div:first-child{
   text-align: left;
}

.contact_icon{
    width:0.14rem;
    height:0.13rem
}

.contact_icon img{
    width: 100%;
    height: 100%;
}

.top_serch{
    width: 3.23rem;
    height: 0.44rem;
    box-shadow: 0 0.04rem 0.26rem 0.01rem rgba(0,0,0,0.25);
    border-radius: 0.09rem;
    box-sizing: border-box;
    background: #fff;
}

.serch_folding{
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 0.15rem 0.15rem 0.15rem 0.11rem;
    font-size: 0.14rem;
    color: #ACACAC;
    border-radius: 0.09rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.serch_folding>div:first-child{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  /*文字隐藏后添加省略号*/
    white-space: nowrap;   /*强制不换行*/
    width: 2.4rem;
}

.serch_icon{
    width: 0.15rem;
    height: 0.15rem;
}

.serch_icon img{
    width: 100%;
    height: 100%;
}

.packUp_item{
    border-bottom: 0.01rem solid #C4C4C4;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 0.03rem;
}
.packUp_item:last-child{
    margin-bottom: 0;
}

.packUp_item_bottom{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.11rem;
}

.packUp_button{
    width: 2.47rem;
    height: 0.44rem;
    background: #1677FF;
    border-radius: 0.09rem;
    line-height: 0.44rem;
    text-align: center;
    color: #fff;
    font-size: 0.16rem;
    margin: 0 auto;
     box-shadow: 0 0.04rem 0.26rem 0.01rem rgba(0,0,0,0.25);
}

.packUp_item2{
    flex: 0 0 35%;
    margin-bottom: 0;
}

.packUp_item3{
    flex: 1;
    margin-left: 0.29rem;
    margin-bottom: 0;

}



.serch_packUp{
    width: 100%;
    height: 100%;
    padding: 0.23rem 0.3rem;
    box-sizing: border-box;
}

.packUp_text{
    font-size: 0.13rem;
    color: #ACACAC ;
}

.packUp_icon{
    width: 0.21rem;
    height: 0.22rem;
    margin-top: 0.06rem;
    flex: 0 0 8%;
}

.packUp_icon img{
    width: 100%;
    height: 100%;
}

.packUp_icon2{
    flex: 0 0 24%;
}

.packUp_icon3{
    flex: 0 0 16%;
}

.packUp_icon4{
    margin-top: 0.08rem;
}

.packUp_right_icon{
    width: 0.11rem;
    height: 0.07rem;
    margin-top: 0.02rem;
}

.packUp_right_icon img{
    width: 100%;
    height: 100%;
}

.pageContent_bottom{
    padding: 0.12rem 0.26rem 0.26rem 0.26rem;
    box-sizing: border-box;
}

.top_p1{
    font-size: 0.14rem;
    padding-bottom: 0.1rem;
    color: #fff;
    letter-spacing: 0.01rem;
    box-sizing: border-box;
}

.top_p2{
    font-size: 0.18rem;
    padding-bottom: 0.2rem;
    color: #fff;
    letter-spacing: 0.03rem;
    box-sizing: border-box;
}

.pageContent_tags{
    overflow: hidden;
    box-sizing: border-box;
}

.tags_item{
    height: 0.22rem;
    border-radius: 0.09rem 0.09rem 0.09rem 0.09rem;
    opacity: 1;
    border: 0.01rem solid #ACACAC;
    box-sizing: border-box;
    padding: 0.05rem 0.08rem 0.04rem 0.12rem;
    float: left;
    margin-right: 0.07rem;
    margin-bottom: 0.05rem;
}

.tags_item div:nth-child(1){
    margin-right: 0.04rem;
    white-space:nowrap;
    font-size: 0.11rem;
    color: #ACACAC;
}

.pageContent_shippingSpace{
    color: #464646;
}

.shippingSpace_top{
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    margin-bottom: 0.13rem;
}

.right_text{
    font-size: 0.12rem;
    color: #1677FF;
    margin-top: 0.01rem;
}

.shippingSpace_item{
    background: #fff;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    height: 0.67rem;
    background: #FFFFFF;
    border-radius: 0.09rem; 
    opacity: 1;
    padding: 0.12rem 0.16rem;
    margin-bottom: 0.04rem;
}

.item_left{
    flex: 0 0 35%;
    min-width: 1.45rem;
}

.item_right{
    text-align: right;
    font-size: 0.12rem;
}

.item_right p:last-child{
    font-size: 0.16rem;
    color: #FF6B00;
    padding-top: 0.14rem;
    box-sizing: border-box;
}

.item_top{
    display: flex;
    justify-content: space-between;
    font-size: 0.12rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
}

.item_top_right{
    margin-right: 0.14rem;
}

.item_text{
    font-size: 0.08rem;
    margin-left: 0.02rem;
}

.item_icon1{
    width: 0.35rem;
    height: 0.05rem;
    margin-top: -0.05rem;
}

.item_icon1 img{
    width: 100%;
    height: 100%;
}

.item_icon2{
    width: 0.11rem;
    height: 0.08rem;
    margin-right: 0.02rem;
}

.item_icon2 img{
    width: 100%;
    height: 100%;
}

.item_icon3{
    width: 0.09rem;
    height: 0.09rem;
    margin-right: 0.02rem;
}

.item_icon3 img{
    width: 100%;
    height: 100%;
}

.item_icon4{
    width: 0.08rem;
    height: 0.08rem;
    margin-top: 0.01rem;
    margin-right: 0.02rem;
}

.item_icon4 img{
    width: 100%;
    height: 100%;
}

.item_bottom{
    display: flex;
    font-size: 0.08rem;
    white-space: nowrap;
}

.item_bottom>div{
    display: flex;
    margin-right: 0.05rem;
}

.item_bottom>div:last-child{
    margin-right: 0;
}

.loading {
    padding: 0.1rem 0;
}
.loading .loading-animation {
    text-align: center;
    height: 0.2rem;
    width: 0.25rem;
    margin: 0 auto;
    position: relative;
}

@keyframes mymove
{
  0% {
      transform: translateX(-0.2rem);
      z-index: 0;
  }


  50% {
      transform: translateX(0.2rem);
      z-index: 1;
  }

  100% {
      transform: translateX(-0.2rem);
      z-index: 0;
  }
}


.loading .loading-animation .loading-item {
    width: 0.14rem;
    height: 0.14rem;
    border-radius: 100%;
    margin: 0 0.05rem;
    position: absolute;
    left: 0;
}
.loading .loading-animation .loading-item {
    width: 0.14rem;
    height: 0.14rem;
    border-radius: 100%;
    margin: 0 0.05rem;
    position: absolute;
    left: 0;
}
.item1 {
    animation-delay: 0s;
    background-color: #969696;
    animation: mymove 1.2s infinite;
  
}
.loading .loading-animation .item2 {
    background-color: #adadad ;
}
.loading .loading-animation .item3 {
    animation-delay: .5s;
    background-color: #d7d7d7;
    
    animation: mymove2 1.2s infinite;
}
@keyframes mymove2
{
0% {
    transform: translateX(20px);
    z-index: 0;
}

50% {
    transform: translateX(-20px);
    z-index: 1;
}

100% {
    transform: translateX(20px);
    z-index: 0;
}
}
.mymove2{
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;

}
.loading .loading-animation .loading-item {
    width: 0.14rem;
    height: 0.14rem;
    border-radius: 100%;
    margin: 0 0.05rem;
    position: absolute;
    left: 0;
}

.loadingCenter{
    text-align: center;
    color: #909399;
}

.nullPage_icon{
    width: 1.2rem;
    margin: 0 auto;
}

.nullPage_icon img{
    width: 100%;
    height: 100%;
}

.nullPage_p1{
    padding: 0.1rem 0;
}
</style> 

<style lang="scss">
.packUp_item3 .el-date-editor .el-input__prefix{
    display: none;
}
.packUp_item3 .el-input--prefix .el-input__inner {
    border: none;
    padding-left: 0;
}

.packUp_item3 .el-date-editor{
    width: 100%;
}

.packUp_item .el-select{
    width: 100%;
}

.packUp_item .el-select .el-input__inner{
    border: none;
    padding: 0 0.3rem 0 0;
}

.packUp_text .el-input__inner{
    width: 100% !important;
    border: none !important;
    padding: 0 0.3rem 0 0;
    background: none;
}
</style>